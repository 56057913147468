<template>
    <div class="container">
        <el-row>
            <content-title :nav="nav"></content-title>
        </el-row>
        <el-descriptions title="顾问信息" v-if="consultant.id">
            <el-descriptions-item label="顾问名称">
                {{ consultant.name }}
            </el-descriptions-item>
            <el-descriptions-item label="所在城市">
                {{ consultant.city }}
            </el-descriptions-item>
            <el-descriptions-item label="状态">
                <el-tag size="small" v-if="consultant.status == 4">待审核</el-tag>
                <el-tag size="small" type="success" v-if="consultant.status == 2">正常</el-tag>
                <el-tag size="small" type="info" v-if="consultant.status == 1">审核拒绝</el-tag>
                <el-tag size="small" type="warning" v-if="consultant.status == 5">下架</el-tag>
                <el-tag size="small" type="danger" v-if="consultant.status == 6">冻结</el-tag>
            </el-descriptions-item>
        </el-descriptions>
        <el-button type="primary" @click="UserChangeShow = true">分配用户</el-button>


        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="分配用户" name="user">
                <el-table :data="userList">
                    <el-table-column prop="user_name" label="用户昵称">
                        <template v-slot="{ row }">
                            <link-to :to="{ name: 'userDetails', params: { id: row.user_id } }"> {{ row.user_name }}</link-to>
                        </template>
                    </el-table-column>
                    <el-table-column prop="admin_name" label="所属顾问"></el-table-column>
                    <el-table-column prop="create_time" label="分配时间"></el-table-column>
                    <el-table-column label="状态">
                        <template v-slot="{ row }">
                            <el-tag v-if="row.status == 1">正常</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template v-slot="{ row }">
                            <el-button type="warning" size="small" @click="cancelConsultant(row)">取消顾问</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <paging-fy @currentPageChange="currentPageChange" :currentPage="currentPage" :total="total"></paging-fy>
            </el-tab-pane>
        </el-tabs>


        <el-dialog title="分配用户" :visible.sync="UserChangeShow" width="40%">

            <el-form :inline="true">
                <el-form-item label="">
                    <el-input v-model="searchVal" placeholder="输入用户电话或昵称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="serach">查询</el-button>
                </el-form-item>
            </el-form>

            <el-transfer v-model="changeUser" :data="searchList"></el-transfer>

            <span slot="footer" class="dialog-footer">
                <el-button @click="UserChangeShow = false">取 消</el-button>
                <el-button type="primary" @click="addBatch">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

export default {

    data() {
        return {
            nav: { firstNav: "顾问管理", secondNav: "顾问详情" },
            id: "",
            consultant: {},
            activeName: 'user',
            userList: [],
            currentPage: 1,
            total: 0,
            manager: JSON.parse(localStorage.getItem("managerInfo")),
            UserChangeShow: false,
            searchVal: '',
            changeUser: [],
            searchList: []
        };
    },

    mounted() {
        this.id = this.$route.params.id
        this.getconsultantInfo()
        this.getUserList()
    },

    methods: {
        addBatch() {
            this.$axios({
                url: "/user/consultantRelative/bindBatch",
                data: {
                    admin_name: this.consultant.name,
                    admin_id: this.consultant.id,
                    users: this.changeUser,
                    add_admin_id: this.manager.id,
                    add_admin_name: this.manager.name
                }
                , method: "post"
            }).then(res => {
                this.$message("绑定顾问成功")
                this.UserChangeShow = false
                this.getUserList()
            })
        },
        serach() {
            const numberReg = /^\d/
            if (this.searchVal != "") {
                let data = {}
                numberReg.test(this.searchVal) ? (data.login_name = this.searchVal) : (data.nick_name = this.searchVal)
                let url = "/user/user/queryByMessage";
                this.$axios
                    .get(url, {
                        params: data
                    })
                    .then((res) => {
                        let arr = []
                        res.data.data.rows.forEach(item => {
                            arr.push({
                                key: item.user_id,
                                label: item.consultant_ids ? item.nick_name + "(已有顾问)" : item.nick_name,
                                disabled: item.consultant_ids ? true : false
                            })
                        })
                        this.searchList = arr;
                    });
            }
        },
        cancelConsultant(row) {
            this.$confirm("确定要取消该用户分配的顾问吗？", "提示").then(() => {
                this.$axios({
                    url: "/user/consultantRelative/cancel",
                    data: {
                        admin_id: row.admin_id,
                        user_id: row.user_id
                    }
                    , method: "post"
                }).then(res => {
                    this.$message("解除顾问成功")
                    this.getUserList()
                })
            })
        },
        handleClick() {

        },
        currentPageChange(v) {
            this.currentPage = v
            this.getUserList()
        },
        getUserList() {
            this.$axios({
                url: "/user/consultantRelative/queryManagerListPage",
                params: {
                    admin_id: this.id,
                    currentPage: this.currentPage,
                    pageSize: 10
                }
            }).then(res => {
                this.userList = res.data.data.rows
                this.total = res.data.data.total
            })
        },
        getconsultantInfo() {
            this.$axios({
                url: "/authorization/managerUser/queryManagerListPage",
                params: {
                    id: this.id
                }
            }).then(res => {
                this.consultant = res.data.data.rows[0]
            })
        }
    },
};
</script>

<style lang="less" scoped></style>